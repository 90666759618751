import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { getDate } from '@Lib/dates'
import { Card, Col, Row, Typography } from 'antd'
import FutureSubNotice from '@Components/FutureSubNotice'
import { isFreeTrial } from '@Lib/helpers/subscriptionHelpers'
import { BILLING_CYCLE_OPTIONS } from '@Constants'

const { Title, Text } = Typography

const PlanDetailsCard = ({
  accountDetails,
  canUpdateSubscriptionOrCard,
  futureSubscription,
  setFutureSubscription,
  refreshSession
}) => {
  const { t } = useTranslation(['common'])

  const [isPlanFreeTrial, setIsPlanFreeTrial] = useState(
    isFreeTrial(accountDetails?.value?.planName)
  )

  // const [selfServiceChangePlan, setSelfServiceChangePlan] = useState(
  //   accountDetails?.value?.canSelfServiceChangePlan
  // )

  const [billingCycleString, setBillingCycleString] = useState('')

  const [nextCharge, setNextCharge] = useState(
    accountDetails?.value?.planRenewsOn
  )

  const [endDate, setEndDate] = useState(accountDetails?.value?.planEndDate)

  useEffect(() => {
    // setSelfServiceChangePlan(accountDetails?.value?.canSelfServiceChangePlan)
    setIsPlanFreeTrial(isFreeTrial(accountDetails?.value?.planName))
    setBillingCycleString(
      accountDetails?.value?.planFrequency === BILLING_CYCLE_OPTIONS.ANNUAL
        ? t('annual_amount')
        : t('monthly_amount')
    )
    setNextCharge(accountDetails?.value?.planRenewsOn)
    setEndDate(accountDetails?.value?.planEndDate)
    // eslint-disable-next-line
  }, [accountDetails])

  if (accountDetails?.isLoading === true) return null

  return (
    <Card className='plan-details' title={t('plan_details')}>
      {futureSubscription && (
        <FutureSubNotice
          futureSubscription={futureSubscription}
          setFutureSubscription={setFutureSubscription}
          currentAccount={accountDetails?.value}
          refreshSession={refreshSession}
          canUpdateSubscriptionOrCard={canUpdateSubscriptionOrCard}
        />
      )}
      <Row gutter={[16, 16]}>
        <Col span={12} gutter={16}>
          <Title
            level={3}
            className='sl-plan-name'
            style={{ textTransform: 'capitalize' }}
          >
            {t(
              accountDetails?.value?.subscriptionName ||
                accountDetails?.value?.planName
            )}
          </Title>
          <Text className='sl-plan-description' type='secondary'>
            {t('current_subscription', {
              name: t(
                accountDetails?.value?.subscriptionName ||
                  accountDetails?.value?.planName
              ),
              frequency: t(accountDetails?.value?.planFrequency)
            })}
          </Text>
          {/* {canUpdateSubscriptionOrCard && selfServiceChangePlan && (
            // <Button type='link' className='sl-change-plan'>
            //   <Link
            //     onClick={() =>
            //       AnalyticsManager.getInstance().trackClick({
            //         action: 'change_plan',
            //         label: EVENT_CATEGORIES.ACCOUNT_PAGE
            //       })
            //     }
            //     to={`/account/${getAccountId(accountDetails)}/available-plans`}
            //   >
            //     {t('change_plan')}
            //   </Link>
            // </Button>
          )} */}
        </Col>
        <Col span={12} className='subscription-info'>
          <ul>
            {accountDetails?.value?.planPrice > 0 && (
              <DisplayNextCharge
                nextCharge={nextCharge}
                endDate={endDate}
                t={t}
              />
            )}
            {endDate && (
              <li>
                <Text type='secondary' className='sl-subscription-end-date'>
                  {t('end_date')}
                  <span>{getDate(accountDetails?.value?.planEndDate)}</span>
                </Text>
              </li>
            )}
            <li>
              {!isPlanFreeTrial && (
                <Text type='secondary' className='sl-subscription-amount'>
                  {billingCycleString}
                  <span>
                    {t('$')}
                    {accountDetails?.value?.planPrice}
                  </span>
                </Text>
              )}
            </li>
          </ul>
          {/* v2.1 prep
          {canUpdateSubscriptionOrCard && (
            <Button type='link'>{t('change_billing_settings')}</Button>
          )} */}
        </Col>
      </Row>
    </Card>
  )
}

const DisplayNextCharge = ({ nextCharge, endDate, t }) => {
  if (nextCharge && (endDate === null || nextCharge < endDate)) {
    return (
      <li>
        <Text type='secondary' className='sl-subscription-renew-date'>
          {t('renews_on')}
          <span>{getDate(nextCharge)}</span>
        </Text>
      </li>
    )
  }
  return null
}

export default PlanDetailsCard
