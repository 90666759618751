import React, { useState, useRef, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
  Col,
  Row,
  Skeleton,
  Spin,
  Card,
  Space,
  Tooltip,
  Typography
} from 'antd'
import { InfoCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons'

import useCurrentSite from '@Hooks/useCurrentSite'
import useAccountDetails from '@Hooks/useAccountDetails'
import useStratticApi from '@Packages/StratticApi/useStratticApi'
import useErrorMessage from '@Hooks/useErrorMessage'

import { Template } from '@Templates/Site'
import { getPendingDomainName } from '@Lib/helpers'
import { isRootDomain } from '@Lib/helpers/sites'

import SectionTitle from '@Components/SectionTitle'
import DefinitionHeader from '@Components/DefinitionHeader'
import DefinitionData from '@Components/DefinitionData'
import DnsData from '@Components/DnsData'
import DomainAddCard from '@Components/DomainAddCard'
import DetachDomain from '@Components/DetachDomain'
import CaaCheck from '@Components/CaaCheck'

import Globe from '@Images/icons/globe.svg'
// import DomainNameRegistrarSelect from '@Components/DomainNameRegistrarSelect'

import { DNS_STATUS, DOMAIN_CONNECTION } from '@Constants'

import './index.less'

const { Text, Title } = Typography

const SiteDomainPage = () => {
  const [currentSite, refetchCurrentSite] = useCurrentSite()
  const [accountDetails] = useAccountDetails()
  const [dnsStatus, setDnsStatus] = useState([])
  const [rootDomain, setIsRootDomain] = useState(
    isRootDomain(currentSite?.value?.customDomain)
  )
  // custom registrar settings being commented out for the moment
  // const [newRegistrar, setNewRegistrar] = useState('')
  const { t } = useTranslation(['common'])
  const timer = useRef(null)
  const [
    { loading: deleteCustomDomainLoading, error: deleteCustomDomainError }
  ] = useStratticApi({}, { manual: true })
  useErrorMessage(deleteCustomDomainError)

  const checkSite = () => {
    refetchCurrentSite()
    timer.current = setTimeout(() => {
      checkSite()
    }, 60 * 1000)
  }

  useEffect(() => {
    setIsRootDomain(isRootDomain(currentSite?.value?.customDomain))
  }, [currentSite])

  useEffect(() => {
    checkSite()
    return () => {
      clearTimeout(timer.current)
    }
    // eslint-disable-next-line
  }, [])

  if (currentSite.isLoading) {
    return <Skeleton />
  }

  if (
    currentSite?.value?.hasCustomDomain !== DOMAIN_CONNECTION.CONNECTED &&
    getPendingDomainName(currentSite)?.length &&
    !currentSite?.value?.hasDomainOverride
  ) {
    return <Redirect to={`/site/${currentSite?.value?.siteId}/domain-setup`} />
  }

  return (
    <Template
      currentSite={currentSite}
      title={t('custom_domain')}
      classNames='domain'
    >
      {deleteCustomDomainLoading ? (
        <Skeleton />
      ) : currentSite?.value?.hasDomainOverride ? (
        <DomainOverrideMessage />
      ) : currentSite?.value?.hasCustomDomain ===
        DOMAIN_CONNECTION.RESETTING ? (
        <Card style={{ textAlign: 'center', padding: 50 }}>
          <Space direction='vertical'>
            <Spin />
            <br />
            {t('updating_custom_domain')}
          </Space>
        </Card>
      ) : accountDetails?.value?.planGroupId === 1 ? (
        <></>
      ) : // <DomainUpgradeCard />
      !currentSite?.value?.customDomain &&
        !currentSite?.value?.liveDistribution?.certificate ? (
        <DomainAddCard siteId={currentSite?.value?.siteId} />
      ) : (
        <>
          <div className='data-list bordered'>
            <section>
              <dl>
                <Row>
                  <Col span={5}>
                    <DefinitionHeader label='domain_name' />
                  </Col>
                  <Col span={13}>
                    <DefinitionData
                      value={
                        currentSite?.value?.customDomain ||
                        getPendingDomainName(currentSite)
                      }
                      showCopy={false}
                    />
                  </Col>
                </Row>
              </dl>
            </section>
            {dnsStatus.find(item => item === DNS_STATUS.PENDING) &&
            !rootDomain ? (
              <SectionTitle
                title='add_dns_records'
                label='please_add_records'
              />
            ) : (
              <SectionTitle title='dns_records' />
            )}
            {currentSite?.value?.liveDistribution?.dnsRecords?.map(
              (record, index) => (
                <DnsData
                  rootDomain={rootDomain}
                  key={index}
                  record={record}
                  final
                  setParentStatus={status => {
                    dnsStatus[index] = status
                    setDnsStatus(dnsStatus)
                  }}
                />
              )
            )}
            <SectionTitle title='ssl_certificate' />
            {currentSite?.value?.liveDistribution?.certificate?.dnsRecords?.map(
              record => (
                <DnsData
                  rootDomain={rootDomain}
                  key={record?.value}
                  record={record}
                  ssl
                />
              )
            )}

            <CaaCheck
              title={
                <span>
                  <Row className='section-title'>
                    <Col span={4}>
                      <Title level={4}>{t('caa_records')}</Title>
                    </Col>
                    <Tooltip title={t('caa_records_tooltip')}>
                      <InfoCircleOutlined />
                    </Tooltip>
                  </Row>
                </span>
              }
              domain={
                currentSite?.value?.customDomain ||
                getPendingDomainName(currentSite)
              }
            />
          </div>
          <Row justify='end'>
            <Col>
              <Tooltip title={t('to_detach_domain')}>
                <QuestionCircleOutlined />
              </Tooltip>{' '}
              <DetachDomain refetch={checkSite} />
            </Col>
          </Row>
        </>
      )}
    </Template>
  )
}

const DomainOverrideMessage = () => {
  const { t } = useTranslation(['common'])
  return (
    <div className='domain-upgrade-card'>
      <div className='back-shadow' />
      <Card className='narrow'>
        <div className='icon-wrapper'>
          <Globe />
        </div>
        <h2>{t('custom_domain_config_title')}</h2>
        <main>
          <p>
            <Text>{t('custom_domain_config_support')}</Text>
          </p>
        </main>
      </Card>
    </div>
  )
}

export default SiteDomainPage
