import './index.less'

const OwnerTrialExpired = ({ accountDetails }) => {
  // const { t } = useTranslation(['common'])
  // const [isModalVisible, setIsModalVisible] = useState(false)
  // const [planGroupId] = useState(getPlanGroupId(accountDetails))
  return null
  // return (
  //   <Card className='narrow owner-trial-card' style={{ marginLeft: '15%' }}>
  //     <Clock />
  //     <h2>
  //       {t('expired_title', {
  //         plan: planGroupId === 1 ? 'Free Trial' : 'Subscription'
  //       })}
  //     </h2>
  //     <main>
  //       <p>
  //         {planGroupId === 1 ? (
  //           <Text>
  //             {t('free_trial_description', {
  //               startedOn: getDate(accountDetails?.planStartDate),
  //               expires: t('expired_regular'),
  //               expiresOn: getDate(accountDetails?.planEndDate)
  //             })}
  //           </Text>
  //         ) : accountDetails?.value?.isPlanPublic ? (
  //           <Text>
  //             {t('public_plan_expired', {
  //               planName: accountDetails?.planName,
  //               planEndDate: getDate(accountDetails?.planEndDate)
  //             })}
  //           </Text>
  //         ) : (
  //           <Text>
  //             {t('custom_plan_expired', {
  //               planEndDate: getDate(accountDetails?.planEndDate)
  //             })}
  //           </Text>
  //         )}
  //       </p>
  //     </main>
  //     <Divider />
  //     {planGroupId === 1 ? (
  //       <h2>{t('ready_to_upgrade')}</h2>
  //     ) : (
  //       <h2>{t('to_continue_using')}</h2>
  //     )}
  //     <main>
  //       {planGroupId === 1 ? (
  //         isFreeTrial(accountDetails?.planName) ? (
  //           <>
  //             <p>
  //               <Text>{t('upgrading_is_easy')}</Text>
  //             </p>
  //             <p>
  //               <Button type='primary see-plans'>
  //                 <Link
  //                   to={`/account/${accountDetails?.accountId}/available-plans`}
  //                 >
  //                   {t('see_plans')}
  //                 </Link>
  //               </Button>
  //             </p>
  //           </>
  //         ) : (
  //           <p>
  //             <Button
  //               type='primary see-plans'
  //               onClick={() => {
  //                 setIsModalVisible(true)
  //               }}
  //             >
  //               {t('contact_sales')}
  //             </Button>
  //             <CustomPlanContactForm
  //               visible={isModalVisible}
  //               setIsModalVisible={() => {
  //                 setIsModalVisible(false)
  //               }}
  //               onCancel={() => {
  //                 setIsModalVisible(false)
  //               }}
  //               company={accountDetails?.accountName}
  //             />
  //           </p>
  //         )
  //       ) : accountDetails?.value?.isPlanPublic ? (
  //         <Button type='primary see-plans'>
  //           <Link to={`/account/${accountDetails?.accountId}/available-plans`}>
  //             {t('renew')}
  //           </Link>
  //         </Button>
  //       ) : (
  //         <p>
  //           <Button
  //             type='primary see-plans'
  //             onClick={() => {
  //               setIsModalVisible(true)
  //             }}
  //           >
  //             {t('contact_sales')}
  //           </Button>
  //           <CustomPlanContactForm
  //             visible={isModalVisible}
  //             setIsModalVisible={() => {
  //               setIsModalVisible(false)
  //             }}
  //             onCancel={() => {
  //               setIsModalVisible(false)
  //             }}
  //             company={accountDetails?.accountName}
  //           />
  //         </p>
  //       )}
  //     </main>
  //   </Card>
  // )
}

export default OwnerTrialExpired
