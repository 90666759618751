import React, { useState, useEffect } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useLocalStorage from '@Hooks/useLocalStorage'

import { Button, Card, Form, Input, Space, Spin, Typography } from 'antd'

import { SITE_STATUS, SUBSCRIPTION_STATUS } from '@Constants'

import useStratticApi from '@Packages/StratticApi/useStratticApi'
import useErrorMessage from '@Hooks/useErrorMessage'
import useAccountDetails from '@Hooks/useAccountDetails'
import useAccountPermissions from '@Hooks/useAccountPermissions'
import DashboardFooter from '@Components/DashboardFooter'
import { Template } from '@Templates/NewSite'

import NewSiteNav from '@Components/NewSiteNav'
import { getWPSiteHealth } from '@Lib/api/sites'
import useWindowDimensions from '@Hooks/useWindowDimensions'

import { useUserContext } from '@Context/UserContext'
import Progress from '@Components/Progress'
import { returnOnlySiteName } from '@Lib/helpers/sites'

import { ACCOUNT_PERMISSIONS } from '@Constants/permissionsConstants'
import BenefitsCarousel from '@Components/BenefitsCarousel'

import Clock from '@Images/icons/clock-loading.png'
import ClockRetina from '@Images/icons/clock-loading-retina.png'
import Illustration from '@Images/illustration.svg'
import BrowserImg from '@Images/browser.svg'
// import { InfoCircleOutlined } from '@ant-design/icons'

import './index.less'

const { Text, Title } = Typography
const { Item } = Form

const NewSitePage = () => {
  const { t } = useTranslation(['common', 'errors'])
  const [accountId] = useLocalStorage('accountId')
  const accountDetails = useAccountDetails()
  const [currentStep, setCurrentStep] = useState(1)
  const [siteName, setSiteName] = useState('')
  const [newSite, setNewSite] = useState(null)
  const [percentLoaded, setPercentLoaded] = useState(0)
  const [newSiteUrl, setNewSiteUrl] = useState()
  const [menuSelectedKey, setMenuSelectedKey] = useState('new-site')
  const hasPermissionToAddSite = useAccountPermissions(
    ACCOUNT_PERMISSIONS.ACCOUNT_SITE_CREATE
  )
  const hasPermissionToUpgrade = useAccountPermissions(
    ACCOUNT_PERMISSIONS.ACCOUNT_SUBSCRIPTION_UPGRADE
  )
  const accountSubStatus = accountDetails?.value?.subscriptionStatus

  const userContext = useUserContext()
  const contentPadding = 51
  const topPadding = userContext.expirationBarPadding ? 50 : 0
  const topIndent = 76 + topPadding
  const { height, width } = useWindowDimensions()

  const countTime = 600
  const minMaxRule = {
    pattern: /^.{3,45}$/,
    message: t('min_max')
  }

  useEffect(() => {
    if (currentStep !== 2) {
      return
    }

    let count = 0
    const percentCounter = setInterval(() => {
      setPercentLoaded(++count)
      if (count === countTime) {
        clearInterval(percentCounter)
      }
    }, 100)

    return () => clearInterval(percentCounter)
  }, [currentStep])
  // Submit new site
  const [
    { loading: getIsNewSiteLoading, error: getNewSiteError },
    submitNewSite
  ] = useStratticApi({}, { manual: true })
  useErrorMessage(getNewSiteError)

  // Get site info
  const [{ error: getSiteInfoError }, getSiteInfo] = useStratticApi(
    { url: `/sites/${newSite?.id}` },
    { manual: true }
  )
  useErrorMessage(getSiteInfoError)

  useEffect(() => {
    if (
      accountDetails?.[0]?.value?.sitesUsage?.current >=
      accountDetails?.[0]?.value?.sitesUsage?.max
    ) {
      setCurrentStep(0)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (newSite?.id) {
      checkSite()
    }
    // eslint-disable-next-line
  }, [newSite])

  const addSite = async () => {
    setCurrentStep(1)
    const response = await submitNewSite({
      url: `accounts/${accountId}/sites`,
      method: 'POST',
      data: {
        displayName: siteName
      }
    })
    const newSiteResponse = response?.data?.result
    if (newSiteResponse) {
      setNewSite(newSiteResponse)
      setCurrentStep(2)
    }
  }

  const startSite = async newSiteInfo => {
    try {
      const siteHealth = await getWPSiteHealth(newSiteInfo)
      if (siteHealth === 1) {
        setNewSiteUrl(makeNewSiteUrl(newSiteInfo))
        setCurrentStep(3)
        setMenuSelectedKey('setup')
      } else {
        setTimeout(() => {
          startSite(newSiteInfo)
        }, 5000)
      }
    } catch (error) {
      setTimeout(() => {
        startSite(newSiteInfo)
      }, 5000)
    }
  }

  const checkSite = async () => {
    const response = await getSiteInfo({ url: `/sites/${newSite?.id}` })
    const newSiteInfo = response?.data?.result
    if (newSiteInfo?.runningStatus === SITE_STATUS.RUNNING) {
      startSite(newSiteInfo)
    } else {
      setTimeout(() => {
        checkSite()
      }, 2500)
    }
  }

  if (
    !hasPermissionToAddSite ||
    accountSubStatus === SUBSCRIPTION_STATUS.EXPIRED
  ) {
    return <Redirect to='/' />
  }

  const makeNewSiteUrl = site => {
    return `https://${site.httpBasicUser}:${site.httpBasicPassword}@${site.stagingDomain}/wp-admin/`
  }

  return (
    <Template title={t('new_site')} classNames='new-site'>
      <table>
        <tbody>
          <tr>
            <td style={{ height: height - topIndent }} className='nav-col'>
              <Title>{t('new_site')}</Title>
              <NewSiteNav selectedKey={menuSelectedKey} />
            </td>
            <td className='content-col'>
              <div
                className='content'
                style={{ height: height - topIndent - contentPadding }}
              >
                {currentStep === 1 ? (
                  <section className='site-name'>
                    <Spin tip={t('loading')} spinning={getIsNewSiteLoading}>
                      <label htmlFor='site_name'>{t('site_name')}</label>
                      <br />
                      <br />
                      <Form onFinish={addSite}>
                        <Item
                          name='site_name'
                          rules={[
                            {
                              required: true,
                              message: t('field_required')
                            },
                            minMaxRule
                          ]}
                          validateTrigger='onChange'
                        >
                          <Input
                            className='sl-siteNameInput'
                            id='site_name'
                            placeholder={t('enter_a_name')}
                            aria-label={t('site_name')}
                            size='large'
                            onChange={event => {
                              setSiteName(
                                returnOnlySiteName(event.target.value)
                              )
                            }}
                          />
                        </Item>
                        <Space>
                          <Button
                            disabled={
                              siteName?.trim().length < 3 ||
                              siteName?.trim().length > 45
                                ? 'disabled'
                                : ''
                            }
                            onClick={() => addSite()}
                            type='primary'
                          >
                            {t('create')}
                          </Button>
                          {/* Hide for now
                            <Button href='migrate-request' id='migrate-site-btn'>
                              {t('migrate_the_site')}
                              <Tooltip
                                title={t('one_two_business_days')}
                                placement='right'
                              >
                                <InfoCircleOutlined />
                              </Tooltip>
                            </Button> */}
                        </Space>
                      </Form>
                    </Spin>
                  </section>
                ) : currentStep === 2 ? (
                  <section className='setup-wp' style={{ width: width - 680 }}>
                    <div className='back-shadow' />
                    <Card className='narrow'>
                      <img
                        src={Clock}
                        srcSet={ClockRetina + ' 2x'}
                        alt='loading...'
                        className='icon'
                      />
                      <h2>{t('please_wait')}</h2>
                      <p>
                        <Text>{t('create_site_waiting_message')}</Text>
                      </p>

                      <p>
                        <Progress max={countTime} current={percentLoaded} />
                      </p>
                    </Card>
                    <BenefitsCarousel />
                  </section>
                ) : currentStep === 3 ? (
                  <Step3Section
                    t={t}
                    newSiteUrl={newSiteUrl}
                    newSite={newSite}
                  />
                ) : (
                  <Upgrade
                    t={t}
                    accountId={accountId}
                    hasPermissionToUpgrade={hasPermissionToUpgrade}
                  />
                )}
              </div>

              <DashboardFooter />
            </td>
          </tr>
        </tbody>
      </table>
    </Template>
  )
}

const Step3Section = ({ newSite, newSiteUrl, t }) => {
  return (
    <section className='ready'>
      <table>
        <tr>
          <td>
            <HeadToWordPressCard t={t} newSiteUrl={newSiteUrl} />
          </td>
          <td>
            <ExploreSiteDetailsCard t={t} newSite={newSite} />
          </td>
        </tr>
        <tr>
          <td colSpan='2'>
            <hr />
          </td>
        </tr>
        <tr>
          <td colSpan='2' style={{ textAlign: 'center' }}>
            <Link to='/'>
              <Button type='default' size='large'>
                {t('go_back_to_dashboard')}
              </Button>
            </Link>
          </td>
        </tr>
      </table>
    </section>
  )
}

const ExploreSiteDetailsCard = ({ newSite, t }) => {
  return (
    <div className='explore-site-details'>
      <BrowserImg />
      <h2>{t('explore_site_details')}</h2>
      <p>{t('explore_site_details_txt')}</p>
      <Button type='primary' className='details-btn sl-detailButton'>
        <Link to={`/site/${newSite?.id}`}>{t('go_to_site_details')}</Link>
      </Button>
    </div>
  )
}

const HeadToWordPressCard = ({ newSiteUrl, t }) => {
  return (
    <div className='head-to-wordpress'>
      <Illustration />
      <h2>{t('head_to_wordpress')}</h2>
      <p>
        {t('head_to_wordpress_txt1')}
        <br />
        {t('head_to_wordpress_txt2')}
      </p>
      <Button
        type='primary'
        href={newSiteUrl}
        target='_blank'
        danger
        size='large'
      >
        {t('go_to_wordpress')}
      </Button>
    </div>
  )
}

const Upgrade = ({ t, accountId, hasPermissionToUpgrade }) => {
  return (
    <>
      <p>{t('exceeded_site_limit')}</p>
      {/* {hasPermissionToUpgrade
        ? (
          // <p>
          //   <Button className='sl-upgradeYourPlan' type='primary'>
          //     <Link to={`/account/${accountId}/available-plans`}>
          //       {t('upgrade_here')}
          //     </Link>
          //   </Button>
          // </p>
          )
        : (
            t('site_limit_contact_owner')
          )} */}
    </>
  )
}

export default NewSitePage
