import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Elements } from '@stripe/react-stripe-js'
import { useTranslation } from 'react-i18next'

import useStratticApi from '@Packages/StratticApi/useStratticApi'
import useErrorMessage from '@Hooks/useErrorMessage'

import { useUserContext } from '@Context/UserContext'
import { Template, Col1, Col2 } from '@Templates/TwoColumn'
import CartCard from '@Components/CartCard'
import { Typography, Row, Col } from 'antd'
import PaymentInfoForm from '@Components/PaymentInfoForm'
import {
  getIsFutureSubscription,
  isCardExpired
} from '@Lib/helpers/subscriptionHelpers'

import { loadStripe } from '@stripe/stripe-js'
import config from '@Lib/config'
import { BILLING_CYCLE } from '@Constants'

import './index.less'

const stripePromise = loadStripe(config.stripePublicApi)

const { Title, Text, Paragraph } = Typography

function useQuery () {
  return new URLSearchParams(useLocation().search)
}

const PaymentInformation = () => {
  const history = useHistory()
  const query = useQuery()
  const planId = query.get('planId')
  const isAnnual = query.get('isAnnual') !== 'false'
  const userContext = useUserContext()
  const [changeCard, setChangeCard] = useState(true)
  const [isFutureSubscription, setIsFutureSubscription] = useState(false)
  const [formStatus, setFormStatus] = useState('')
  const [accountDetails] = useState(userContext?.accountDetails)
  const [accountId] = useState(userContext?.accountId)
  const [getCountriesPath] = useState('processor/countries')
  const [expired, setExpired] = useState(false)
  const [
    { data: countryList, loading: countryListLoading, error: countryListError }
  ] = useStratticApi({
    url: getCountriesPath,
    method: 'GET'
  })
  useErrorMessage(countryListError)

  const [getCardPath] = useState(`/accounts/${accountId}/cards`)
  const [
    { data: cardData, loading: cardDataLoading, error: cardDataError }
  ] = useStratticApi({
    url: getCardPath,
    method: 'GET'
  })
  useErrorMessage(cardDataError)

  const [intentPath] = useState(`/accounts/${accountId}/cards/init`)
  const [
    { data: secret, loading: secretLoading, error: secretError },
    getSecret
  ] = useStratticApi({}, { manual: true })
  useErrorMessage(secretError)

  useEffect(() => {
    if (cardData?.length) {
      setChangeCard(false)
    }
    // eslint-disable-next-line
  }, [cardData])

  useEffect(() => {
    setExpired(isCardExpired(cardData?.[0]?.card))
  }, [cardData])

  useEffect(() => {
    setIsFutureSubscription(
      getIsFutureSubscription(userContext?.cart?.startDate)
    )
    // eslint-disable-next-line
  }, [userContext?.cart])

  useEffect(() => {
    let intent

    const shouldSetupIntent = isFutureSubscription || cardData?.[0]?.id
    if (shouldSetupIntent) {
      intent = { type: 'setup' }
    } else {
      intent = {
        type: 'payment',
        planId: query.get('planId'),
        billingCycle: isAnnual ? BILLING_CYCLE.ANNUAL : BILLING_CYCLE.MONTHLY
      }
    }
    if (!cardDataLoading) {
      getSecret({
        url: intentPath,
        method: 'POST',
        data: intent
      })
    }
    // eslint-disable-next-line
  }, [userContext?.cart, cardData, cardDataLoading])

  useEffect(() => {
    if (!accountDetails || !accountId) {
      history.push('/account/')
    }
    if (
      !userContext.cart ||
      userContext.cart === null ||
      planId === null ||
      isAnnual === null
    ) {
      // history.push(`/account/${accountId}/available-plans`)
    }
  }, [userContext.cart, accountDetails, history, planId, isAnnual, accountId])

  return (
    <Template classNames='payment-information-page' showFooter={false}>
      <Col1>
        {!secretLoading && !cardDataLoading && (
          <CheckoutTitle changeCard={changeCard || expired} />
        )}
        {/* <p>
          {t('is_annual')}: {query.get('isAnnual')}
        </p>
        <p>
          {t('plan_id')}: {query.get('planId')}
        </p> */}

        <Elements stripe={stripePromise}>
          <PaymentInfoForm
            setChangeCard={setChangeCard}
            formStatus={formStatus}
            setFormStatus={setFormStatus}
            userId={userContext?.currentUser?.id}
            accountDetails={userContext?.accountDetails}
            accountId={accountId}
            plan={userContext.cart}
            countryList={countryList}
            countryListLoading={countryListLoading}
            cardData={cardData}
            cardDataLoading={cardDataLoading}
            changeCard={changeCard || expired}
            isFutureSubscription={isFutureSubscription}
            secret={secret?.result}
            planId={parseInt(planId, 10)}
            isAnnual={isAnnual}
            sessionCall={() => userContext.login()}
            spinning={secretLoading || cardDataLoading}
          />
        </Elements>
      </Col1>
      <Col2>
        {userContext?.cart && (
          <CartCard cart={userContext?.cart} currentAccount={accountDetails} />
        )}
      </Col2>
    </Template>
  )
}

const CheckoutTitle = ({ changeCard }) => {
  const { t } = useTranslation(['common'])
  if (changeCard) {
    return (
      <>
        <Title>{t('payment_information')}</Title>
        <Row>
          <Col md={24} lg={22} xxl={18}>
            <Text>{t('payment_information_description')}</Text>
          </Col>
        </Row>
      </>
    )
  }
  return (
    <>
      <Title>{t('review_order')}</Title>
      <Row>
        <Col>
          <Paragraph>{t('change_or_cancel_sub')}</Paragraph>
        </Col>
      </Row>
    </>
  )
}

export default PaymentInformation
